@font-face {
  font-family: 'icomoon';
  src:  url('/font/icomoon.eot?b44gz3');
  src:  url('/font/icomoon.eot?b44gz3#iefix') format('embedded-opentype'),
    url('/font/icomoon.ttf?b44gz3') format('truetype'),
    url('/font/icomoon.woff?b44gz3') format('woff'),
    url('/font/icomoon.svg?b44gz3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak-as: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin: 0 10px 0 0;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-carrot:before {
  content: "\e900";
}
.icomoon-pin:before {
  content: "\e901";
}
.icomoon-reward:before {
  content: "\e902";
}
.icomoon-clock:before {
  content: "\e905";
}
.icomoon-chevron-thin-up:before {
  content: "\e906";
}
.icomoon-map-marker:before {
  content: "\e907";
}
.icomoon-facebook:before {
  content: "\e908";
}
.icomoon-phone:before {
  content: "\e909";
}
.icomoon-chevron-thin-left:before {
  content: "\e90a";
}
.icomoon-chevron-thin-right:before {
  content: "\e90b";
}
.icomoon-line:before {
  content: "\e919";
}
.icomoon-mail:before {
  content: "\f003";
}