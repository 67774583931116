.brand_navbar {
  padding: 10px 0 10px 13px;
  @include tablets {
    padding: 10px 0 10px 20px;
  }
  @include desktops {
    display: flex;
    align-content: center;
    padding: 20px 4%;
  }
  .navbar-brand {
    display: flex;
    padding: 0;
    margin: 0;
    .logo {
      max-width: 84px;
      margin-right: 20px;
      @include desktops {
        max-width: 150px;
      }
    }
    p {
      margin: 0;
      font-size: 13px;
      align-self: flex-end;
    }
  }
  .navbar-nav {
    display: none;
    @include desktops {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      flex: 0 0 46%;
      margin: 0 auto;
    }
    @include desktops_1920 {
      margin: 0 120px 0 auto;
    }
    .nav-link {
      padding: 0;
      font-size: 15px;
      color: $black;
      text-align: center;
      transition: color .3s linear;
      &:hover {
        color: #6E6E6E;
      }
      @include desktops_1920 {
        font-size: 16px;
      }
    }
    span {
      display: block;
      &:nth-child(1) {
        font-family: 'Sorts Mill Goudy';
        font-size: 12px;
        margin-bottom: 2px;
        color: #C08933;
        @include desktops_1920 {
          font-size: 14px;
        }
      }
    }
  }
  .tools_link {
    display: none;
    @include desktops {
      display: flex;
      align-items: center;
    }
    a {
      font-size: 16px;
      color: $black;
      transition: color .3s linear;
      &:hover {
        color: #6E6E6E;
      }
    }
    .chose_language {
      display: flex;
      margin-right:  17px;
      @include desktops{
        margin-right: 12px;
      }
      @include desktops_1920 {
        margin-right: 11px;
      }
      .language {
        display: flex;
        width: 22px;
        height: 22px;
        justify-content: center;
        &.active {
          background-color: $black;
          color: $white;
        }
      }
      span {
        margin: 0 6px;
      }
    }
    .social_media {
      a:first-child {
        margin-right: 5px;
      }
    }
  }
}