.ham_menu {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  width: 45px;
  height: 45px;
  background-color: $white;
  transition: all .15s ease-in;
  &.active {
    background-color: transparent;
    span {
      &:nth-child(1) {
        transform: translate(-50%, calc(-50%)) rotateZ(45deg);
      }
      &:nth-child(2) {
        transform: translate(100%,  -50%);
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translate(-50%, calc(-50%)) rotateZ(-45deg);
      }
    }
  }
  @include tablets {
    display: none;
  }
  span {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .15s ease-in;
    width: 25px;
    height: 2px;
    border-radius: 2px;
    background-color: $black;
    opacity: 1;
    overflow: hidden;
    &:nth-child(1) {
      transform: translate(-50%, calc(-50% - 8px));
    }
    &:nth-child(3) {
      transform: translate(-50%, calc(-50% + 8px));
    }
  }
}
.mobile_menu {
  display: block;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 0%;
  background-color: #D6C0A2;
  transition: height .3s ease-in, padding .3s ease-in;
  overflow-y: auto;
  overflow-x: hidden;
  &.active {
    height: 100%;
    padding: 55px 0 72px 0;
    .logo {
      opacity: 1;
      // animation: rollingScale .2s ease-in forwards;
      // animation-delay: .35s;
    }
    .menu_list {
      opacity: 1;
      transform: translateY(0px);
    }
  }
  @include tablets {
    display: none;
  }
  > div {
    margin: 0 auto 0 auto;
  }
  .logo {
    max-width: 123px;
    margin-bottom: 42px;
    opacity: 0;
    transition: opacity .2s .3s ease-in;
  }
  .menu_list {
    margin: 0 auto 168px auto;
    max-width: 216px;
    display: flex;
    justify-content: space-between;
    opacity: 0;
    transform: translateY(20px);
    transition: all .4s .3s ease-in;
    li {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      a {
        color: $black;
        writing-mode: vertical-rl;
        transition: color .3s linear;
        &:hover {
          color: #6E6E6E;
        }
        &:first-child {
          min-height: 76px;
          font-size: 13px;
          font-weight: 300;
        }
        &:last-child {
          font-family: 'TrajanPro3'; 
        }
      }
    }
  }
  .social_media,
  .chose_language {
    a {
      color: $black;
      transition: color .3s linear;
      &:hover {
        color: #6E6E6E;
      }
    }
  }
  .social_media {
    margin-bottom: 4px;
  }
  .chose_language {
    margin-bottom: 8px;
    a {
      font-size: 14px;
    }
  }
  .copyright {
    max-width: 296px;
    font-family: 'Noto Sans', 'Noto Sans TC', 'Noto Sans SC', 'Noto Sans JP', sans-serif;
    p {
      margin: 0;
      font-size: 12px;
      font-weight: 300;
      &:first-child {
        margin-bottom: 4px;
      }
    }
  }
}
.banner_section {
  position: relative;
  .swiper-container {
    position: relative;
    height: calc(var(--vh, 1vh) * 100 - (96px - 32px));
    min-height: calc(var(--vh, 1vh) * 100 - (96px - 32px));
    @include tablets {
      height: calc(var(--vh, 1vh) * 100 - (143px - 65px));
      min-height: calc(var(--vh, 1vh) * 100 - (143px - 65px));
    }
    @include desktops {
      height: calc(var(--vh, 1vh) * 100 - (200px - 100px));
      min-height: calc(var(--vh, 1vh) * 100 - (200px - 100px));
    }
    @include desktops_1920 {
      height: calc(var(--vh, 1vh) * 100 - (275px - 158px));
      min-height: calc(var(--vh, 1vh) * 100 - (275px - 158px));
    }
    .swiper-wrapper,
    .swiper-slide {
      height: 100%;
    }
    .swiper-slide {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center top;
      @include desktops {
        width: 43.666667%;
      }
      @include desktops_1920 {
        width: 41.354167%;
      }
      &.slide_1 {
        background-image: url('/images/banner_slider_1.jpg');
      }
      &.slide_2 {
        background-image: url('/images/banner_slider_2.jpg');
      }
      &.slide_3 {
        background-image: url('/images/banner_slider_3.jpg');
      }
    }
  }
  .navigation_group {
    position: absolute;
    top: 80%;
    right: 5%;
    @include desktops{
      display: none;
    }
  }
  .button-next,
  .button-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 5;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid $white;
    color: $white;
    &:active,
    &:focus {
      outline: none;
    }
    i {
      position: relative;
      &:before {
        margin: 0;
        // vertical-align: middle;
      }
    }
  }
  .button-next {
    margin-bottom: 10px;
  }
  // .container {
  //   text-align: center;
  //   position: absolute;
  //   top: 162px;
  //   left: 50%;
  //   z-index: 5;
  //   transform: translate(-50%, 0);
  //   @include tablets {
  //     top: 225px;
  //   }
  //   @include desktops {
  //     max-width: 830px;
  //     top: 190px;
  //     text-align: left;
  //   }
  //   @include desktops_1440 {
  //     max-width: 1040px;
  //     top: 286px; 
  //   }
  // }
  // h1 {
  //   max-width: 116px;
  //   padding: 0 13px;
  //   margin: 0 auto 20px auto;
  //   font-family: 'FutoMinA101Pr5-Bold-AlphaNum';
  //   font-size: 22px;
  //   line-height: 31px;
  //   background-color: rgba($white, .5);
  //   @include tablets {
  //     max-width: 174px;
  //     padding: 0 30px;
  //     margin-bottom: 34px;
  //     font-size: 28px;
  //     line-height: 46px;
  //   }
  //   @include desktops {
  //     max-width: 179px;
  //     padding: 0 13px 0 20px;
  //     margin: 0 auto 20px 0;
  //     margin-bottom: 30px;
  //     background-color: rgba(#D6C0A2, .5);
  //     font-size: 36px;
  //     line-height: 48px;
  //   }
  //   @include desktops_1920 {
  //     max-width: 207px;
  //     margin-bottom: 34px;
  //     line-height: 57px;
  //     padding: 0 37px 0 24px;
  //   }
  // }
  // h3 {
  //   max-width: 183px;
  //   padding: 0 28px;
  //   margin: 0 auto 0 auto;
  //   font-size: 14px;
  //   line-height: 26px;
  //   text-align: center;
  //   background-color: rgba($white, .5);
  //   @include tablets {
  //     max-width: 202px;
  //     font-size: 16px;
  //     line-height: 29px;
  //   }
  //   @include desktops {
  //     max-width: 280px;
  //     margin: 0;
  //     padding: 0 115px 0 20px;
  //     background-color: rgba(#D6C0A2, .5);
  //     line-height: 40px;
  //   }
  //   @include desktops_1920{
  //     max-width: 238px;
  //     padding: 0 42px 0 24px;
  //     line-height: 46px;
  //   }
  // }
  .scroll_hint {
    position: absolute;
    left: 27px;
    bottom: 56px;
    z-index: 9;
    font-size: 13px;
    font-family: 'Garamond MT Std';
    color: $white;
    letter-spacing: 1px;
    writing-mode: vertical-rl;
    animation: scroll .5s infinite ease-in alternate;
    @include desktops {
      color: $white;
      left: 50%;
      bottom: 75px;
      animation: none;
    }
    @include desktops_1920 {
      font-size: 16px;
      bottom: 100px;
    }
    &:before {
      @include desktops {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        width: 1px;
        height: 78px;
        background-color: $white;
      }
      @include desktops_1920 {
        height: 63px;
      }
    }
    span {
      @include desktops {
        color: $black;
      }
      &:first-child {
        @include desktops {
          color: $white;
        }
        @include desktops_1920 {
          color: $black;
        }
      }
      // @include desktops {
      //   animation: scroll .5s infinite ease-in alternate,
      //             scroll_color .5s infinite ease-in alternate;
      // }
    }
    .scroll_arrow {
      width: 5px;
      position: absolute;
      top: 0;
      left: 0;
      &:nth-child(3) {
        @include desktops {
          display: none;
        }
      }
      &:last-child {
        display: none;
        @include desktops {
          display: block;
        }
        top: 50%;
        left: 0px;
      }
    }
  }
}
.about_section {
  padding-bottom: 40px;
  .section_top_img {
    margin-bottom: 50px;
    // height: 210px;
    // background-image: url('/images/about_bg_m.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @include tablets {
      margin-bottom: 70px;
      // height: 430px;
    }
    @include desktops {
      // background-image: url('/images/about_bg_l.jpg');
      background-position: center center;
      margin-bottom: 80px;
      // height: 500px;
    }
    @include desktops_1440 {
      // background-image: url('/images/about_bg_l.jpg');
      margin-bottom: 80px;
      // height: 650px;
    }
    @include desktops_1920 {
      margin-bottom: 80px;
      // height: 800px;
    }
  }
  .container {
    @include desktops {
      max-width: 710px;
    }
  }
  .about_card {
    margin-bottom: 30px;
    color: #4a4a4a;
    @include tablets {
      padding: 0 10px;
    }
    .card_img {
      max-width: 110px;
      margin: 0 auto 30px auto;
    }
    .card_title {
      margin-bottom: 5px;
      font-family: 'Noto Serif', 'Noto Serif TC', 'Noto Serif SC', 'Noto Serif', 'Noto Serif JP', serif;
      font-size:  14px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
    }
    .card_desc {
      max-width: 110px;
      margin: 0 auto;
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      word-break: break-all;
    }
  }
  .history_course {
    position: relative;
    margin-bottom: 30px;
    margin-top: 38px;
    @include tablets {
      margin-top: 58px;
    }
    @include desktops {
      max-width: 700px;
      padding: 0;
      margin: 58px auto 30px auto;
    }
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
      width: 100%;
      height: 2px;
      background-color: #F0F0F0;
    }
    li {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 8px;
      border-bottom: 2px solid transparent;
      font-family: 'Noto Sans', 'Noto Sans TC', 'Noto Sans SC', 'Noto Sans JP', sans-serif;
      font-size: 13px;
      line-height: 19px;
      font-weight: 300;
      cursor: pointer;
      transition: all .3s ease-in;
      @include desktops {
        font-size: 14px;
      }
      &.active {
        color: #C08933;
        border-bottom: 2px solid #C08933;
      }
      &:hover {
        border-bottom: 2px solid #C08933;
      }
    }
  }
  .course_step {
    display: none;
    font-family: 'Noto Sans', 'Noto Sans TC', 'Noto Sans SC', 'Noto Sans JP', sans-serif;
    font-weight: 300;
    transition: display .3s ease-in;
    overflow: hidden;
    @include tablets {
      max-width: 563px;
      margin: 0 auto;
    }
    @include desktops {
      max-width: 660px;
    }
    @include desktops_1920 {
      max-width: 700px;
    }
    &.active {
      display: block;
    }
    > div {
      margin-bottom: 30px;
    }
    .course_year {
      display: block;
      margin-bottom: 12px;
      color: #C08933;
      font-size: 15px;
      @include tablets {
        margin-bottom: 18px;
      }
    }
    ul {
      padding-bottom: 7px;
      border-bottom: 1px solid #F0F0F0;
      @include tablets {
        padding-bottom: 11px;
      }
      @include desktops {
        padding-bottom: 13px;
      }
    }
    li {
      margin-bottom: 10px;
      font-size: 13px;
      line-height: 22px;
      @include tablets {
        margin-bottom: 20px;
      }
      @include desktops {
        margin-bottom: 16px;
        font-size: 15px;
      }
    }
  }
}
.brands_section {
  .section_top_img {
    margin-bottom: 50px;
    display: flex;
    justify-content: space-between;
    @include tablets {
      margin-bottom: 70px;
    }
    @include desktops {
      margin-bottom: 80px;
    }
    @include desktops_1920 {
      margin-bottom: 90px;
    }
    .first_img, .second_img {
      flex: 0 0 100%;
      height: 210px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      @include tablets {
        flex: 0 0 calc(50% - 3px);
        height: 237px;
      }
      @include desktops {
        flex: 0 0 calc(50% - 5px);
        height: 370px;
      }
      @include desktops_1920 {
        flex: 0 0 calc(50% - 7px);
        height: 592px;
      }
    }
    .first_img {
      background-image: url('/images/brand_top_bg1.jpg');
    }
    .second_img {
      background-image: url('/images/brand_top_bg2.jpg');
    }
  }
  .container {
    margin-bottom: 50px;
    @include tablets {
      max-width: 677px;
      margin-bottom: 70px;
    }
    @include desktops {
      // max-width: 774px;
      max-width: 960px;
      margin-bottom: 110px;
    }
    @include desktops_1920 {
      margin-bottom: 120px;
    }
  }
  .brand {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-bottom: 45px;
    height: 100px;
    margin: 0 auto;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent;
    transition: all .2s ease-in;
    &:hover {
      background-color: #EEE9E4;
    }
    @include tablets {
      // max-width: 50%;
      height: 130px;
    }
    @include desktops {
      // height: 145px;
    }
  }
}
.recruit_section {
  background-color: #EEE9E4;
  padding-bottom: 145px;
  @include tablets {
    padding-bottom: 70px
  }
  @include desktops {
    padding-bottom: 65px
  }
  @include desktops_1440 {
    padding-bottom: 120px
  }
  @include desktops_1920 {
    padding-bottom: 180px
  }
  .section_top_img {
    margin-bottom: 50px;
    height: 232px;
    background-image: url('/images/recruit_bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @include tablets {
      margin-bottom: 70px;
      height: 475px;
    }
    @include desktops {
      margin-bottom: 65px;
      height: 742px;
    }
    @include desktops_1440 {
      margin-bottom: 75px;
      height: 965px;
    }
    @include desktops_1920 {
      margin-bottom: 120px;
      height: 1187px;
    }
  }
  .container {
    text-align: center;
  }
  .section_content {
    margin-bottom: 45px;
    @include tablets {
      margin-bottom: 57px;
      line-height: 27px;
    }
    @include desktops {
      max-width: 756px;
      margin: 0 auto 54px auto;
    }
  }
  .link {
    padding: 8px 34px;
    @include tablets {
      padding: 8px 67px;
    }
  }
}
.contact_section {
  padding: 50px 0 60px 0;
  @include tablets {
    padding: 60px 0 150px 0;
  }
  @include desktops {
    padding: 50px 0 160px 0;
  }
  @include desktops_1920 {
    padding: 90px 0 156px 0;
  }
  .container {
    @include desktops_1920 {
      max-width: 1040px;
    }
  }
  .map {
    height: 210px;
    margin-top: 40px;
    @include tablets {
      height: 275px;
      margin-top: 70px;
    }
    @include desktops {
      height: 409px;
      margin-top: 80px;
    }
    @include desktops_1920{
      height: 440px;
    }
    iframe {
      width: 100%;
      height: 210px;
      @include tablets {
        height: 275px;
      }
      @include desktops {
        height: 409px;
      }
      @include desktops_1920{
        height: 440px;
      }
    }
  }
  ul {
    // margin: 0 -15px;
    // @include tablets {
    //   margin: 0;
    // }
  }
  .contact_transportation {
    @include desktops {
      margin: 0 auto;
      max-width: 295px;
    }
    @include desktops_1920 {
      max-width: 326px;
      margin: 0 0 0 50px;
    }
  }
  .transportation_tool {
    margin-bottom: 12px;
    font-size: 14px;
    .name {
      margin-bottom: 4px;
      color: #C08933;
      line-height: 20px;
      @include desktops_1920 {
        font-size: 16px;
      }
    }
    .route {
      color: #4A4A4A;
      font-weight: 300;
      line-height: 20px;
      @include desktops_1920 {
        font-size: 15px;
      }
    }
  }
  .contact_methods {
    // @include tablets {
    //   margin-left: 55px;
    // }
    // @include laptop {
    //   margin-left: 130px;
    // }

    // @include desktops {
    //   margin-left: 135px;
    // }
    // @include desktops_1920 {
    //   margin-left: 150px;
    // }
    li {
      margin-bottom: 3px;
      color: #4a4a4a;
    }
    span {
      // margin-left: 7px;
      font-size: 14px;
      font-weight: 300;
      @include desktops_1920 {
        font-size: 15px;
      }
    }
  }
  .link {
    position: relative;
    // left: 50%;
    // transform: translateX(-50%);
    margin-top: 40px;
    padding: 8px 34px;
    font-family: 'Noto Sans', 'Noto Sans TC', 'Noto Sans SC', 'Noto Sans JP', sans-serif;
    @include tablets {
      margin-top: 38px;
      padding: 8px 57px;
    }
    @include desktops_1920 {
      margin-top: 34px;
    }
  }
}
.footer {
  position: relative;
  background-color: #EEE9E4;
  padding: 64px 25px;
  .go_top {
    position: absolute;
    z-index: 1;
    top: -40px;
    right: 0;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F0F0F0;
    cursor: pointer;
    i {
      color: $black;
      font-size: 16px;
    }
  }
  .footer_logo {
    display: flex;
    justify-content: center;
    max-width: 150px;
    margin: 0 auto 24px auto;
  }
  .contact_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #4a4a4a;
    font-size: 14px;
  }
  .social_link {
    display: flex;
    align-items: center;
    margin-top: 15px;
    a {
      color: #4a4a4a;
      margin: 0 5px;
    }
    i {
      font-size: 15px;
    }
  }
  .footer_menu {
    max-width: 660px;
    margin: 50px auto 0 auto;
    @include desktops {
      max-width: 733px;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      border-right: 1px solid $black;
      line-height: 16px;
      &:last-child {
        border: none;
      }
    }
    a {
      color: $black;
      font-size: 14px;
      font-weight: 300;
      transition: all .3s linear;
      &:hover {
        color: #6E6E6E;
      }
    }
  }
}