nav.navbar {
  position: relative;
  z-index: 5;
  display: block;
  background-color: $white;
  width: 100%;
  height: calc(96px - 32px);
  min-height: calc(96px - 32px);
  max-height: calc(96px - 32px);
  padding: 0 55px;
  @include tablets {
    height: calc(143px - 65px);
    min-height: calc(143px - 65px);
    max-height: calc(143px - 65px);
  }
  @include desktops {
    height: calc(200px - 100px);
    min-height: calc(200px - 100px);
    max-height: calc(200px - 100px);
  }
  @include desktops_1920 {
    height: calc(275px - 158px);
    min-height: calc(275px - 158px);
    max-height: calc(275px - 158px);
  }
  &:before {
    content: '';
    border-bottom: 32px solid $white;
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
    position: absolute;
    top: -32px;
    left: 0;
    width: 100%;
    height: 0;
    @include tablets {
      border-bottom: 65px solid $white;
      top: -65px;
    }
    @include desktops {
      border-bottom: 100px solid $white;
      top: -100px;
    }
    @include desktops_1920 {
      border-bottom: 158px solid $white;
      top: -158px;
    }
  }
  &.fixed {
    @include desktops {
      padding: 0 80px;
      display: flex;
      justify-content: flex-start;
      position: fixed;
      top: -80px;
      left: 0;
      height: 80px;
      min-height: 80px;
      max-height: 80px;
      animation: slideDown .25s ease-in forwards;
      .navbar-brand {
        display: flex;
        top: 0;
        margin: 0;
        align-items: flex-end;
        .logo {
          max-width: 150px;
          margin: 0 20px 0 0;
        }
      }
      .navbar-nav {
        top: 0;
        max-width: none;
        padding: 0;
        flex: 1 1 auto;
        justify-content: flex-start;
        @include desktops_1920 {
          margin: 0 0 0 308px;
        }
        .nav-link {
          flex: 1 1;
        }
        .tools_link {
          display: flex;
          position: relative;
          padding: 0;
          top: 0;
          left: 0;
          @include desktops_1920 {
            margin: 0 0 0 120px;
          }
        }
      }
      > .tools_link {
        display: none;
      }
    }
  }
  .navbar-brand {
    display: block;
    margin: 0 auto;
    padding: 0;
    position: relative;
    text-align: center;
    top: -10px;
    @include tablets {
      top: -34px;
    }
    @include desktops {
      top: -60px;
    }
    @include desktops_1920 {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      top: -85px;
    }
    .logo {
      top: -10px;
      max-width: 100px;
      margin: 0 auto 10px auto;
      @include tablets {
        max-width: 156px;
      }
      @include desktops {
        max-width: 148px;
      }
      @include desktops_1920 {
        max-width: 210px;
        margin: 0 20px 0 0;
      }
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      @include tablets {
        font-size: 16px;
      }
    }
  }
  .navbar-nav {
    display: none;
    @include tablets {
      // max-width: 817px;
      // margin: 0 auto;
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      // align-items: center;
      // position: relative;
      // top: -35px;
    }
    @include desktops {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      max-width: 625px;
      margin: 0 auto;
      top: -45px;
    }
    @include desktops_1920 {
      max-width: 1040px;
      padding-right: 150px;
      top: -50px;
    }
    .nav-link {
      text-align: center;
      padding:  0;
    }
    a {
      font-size: 15px;
      color: $black;
      transition: color .3s linear;
      &:hover {
        color: #6E6E6E;
      }
      @include desktops_1920 {
        font-size: 16px;
      }
    }
    span {
      display: block;
      &:nth-child(1) {
        font-family: 'Sorts Mill Goudy';
        font-size: 12px;
        margin-bottom: 2px;
        color: #C08933;
        @include desktops_1920 {
          font-size: 14px;
        }
      }
    }
    .tools_link {
      display: none;
      @include desktops_1920 {
        display: flex;
        padding: 0;
        width: 200px;
        position: absolute;
        top: 45%;
        left: 100%;
      }
    }
  }
  .tools_link {
    display: none;
    @include tablets {
      display: flex;
      position: fixed;
      width: auto;
      padding: 12px 15px 12px 15px;
      top: 0;
      right: 0;
      z-index: 10;
      background-color: $white;
    }
    @include desktops {
      width: auto;
      padding: 10px 15px;
    }
    @include desktops_1920 {
      display: none;
    }
    a {
      font-size: 16px;
      color: $black;
      transition: color .3s linear;
      &:hover {
        color: #6E6E6E;
      }
    }
    .chose_language {
      display: flex;
      margin-right:  17px;
      @include desktops{
        margin-right: 12px;
      }
      @include desktops_1920 {
        margin-right: 11px;
      }
      .language {
        display: flex;
        width: 22px;
        height: 22px;
        justify-content: center;
        align-items: center;
        &.active {
          background-color: $black;
          color: $white;
        }
      }
      span {
        margin: 0 6px;
      }
    }
    .social_media {
      a:first-child {
        margin-right: 5px;
      }
    }
  }
}