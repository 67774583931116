.yonglinpage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .container {
    padding: 0 15px;
    @include tablets {
      max-width: 709px;
    }
    @include desktops {
      max-width: 1110px;
    }
  }
  .header {
    position: relative;
    height: 172px;
    @include tablets {
      height: 122px;
    }
    @include desktops {
      height: 114px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 10px;
      background-color: #00BB6B;
    }
    .logo {
      max-width: 216px;
      padding-top: 27px;
      @include tablets {
        max-width: 240px;
      }
      @include desktops {
        max-width: 246px;
      }
    }
  }
  .farms_section {
    margin-bottom: 40px;
    padding: 20px 0 90px 0;
    background-color: #EBEBE3;
    @include tablets {
      padding: 30px 0 102px 0 ;
    }
    @include desktops {
      padding: 40px 0 96px 0;
      margin-bottom: 60px;
    }
    .banner {
      height: 230px;
      margin-bottom: 30px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url('/images/yonglin/banner/banner_001_s.jpg');
      @include tablets {
        height: 320px;
        margin-bottom: 50px;
        padding: 24px 30px 0 0;
        background-image: url('/images/yonglin/banner/banner_001_l.jpg');
      }
      @include desktops {
        margin-bottom: 40px;
        padding: 0 50px 0 0;
      }
      p {
        // font-family: 'CSong3HK';
        font-size: 24px;
        color: $white;
        line-height: 49px;
      }
    }
    .farm_card {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 18px 13px 14px 13px;
      background-color: $white;
      border-radius: 15px;
      transition: all .3s ease;
      &:hover {
        background-color: #00BB6B;
        h2 {
          color: $white;
          border-bottom-color: $white;
        }
        p {
          color: $white;
        }
        .farm_detail_btn {
          color: $white;
          border-color: $white;
          @include desktops {
            border-color: $white;
          }
        }
      }
      @include tablets {
        padding: 15px 17px 16px 17px;
      }
      @include desktops {
        padding: 28px 20px 20px 20px;
      }
      h2 {
        margin-bottom: 16px;
        padding-bottom: 5px;
        border-bottom: 2px solid #00BB6B;
        // font-family: DFMingStd;
        font-size: 16px;
        transition: all .3s ease;
        @include tablets {
          margin-bottom: 25px;
          padding-bottom: 9px;
          font-size: 20px;
        }
        @include desktops {
          margin-bottom: 24px;
          padding-bottom: 10px;
          border-bottom: 3px solid #00BB6B;
          font-size: 24px;
        }
      }
      p {
        display: -webkit-box;
        height: 77px;
        text-overflow: ellipsis;
        text-align: justify;
        overflow:hidden;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        margin-bottom: 13px;
        // font-family: DFHeiSt;
        font-size: 13px;
        font-weight: 500;
        transition: all .3s ease;
        @include tablets {
          height: 105px;
          -webkit-line-clamp: 5;
          margin-bottom: 8px;
          font-size: 14px;
        }
        @include desktops {
          height: 112px;
          margin-bottom: 6px;
          font-size: 15px;
        }
      }
      .farm_detail_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 124px;
        height: 39px;
        border: 1px solid #00BA6E;
        border-radius: 23.5px;
        color: #00BA6E;
        // font-family: DFHeiSt;
        font-size: 16px;
        font-weight: 700;
        @include desktops {
          width: 154px;
          height: 47px;
          font-size: 18px;
          background-color: #00BA6E;
          color: $white;
          transition: all .3s ease;
        }
        &:hover {
          @include desktops {
            background-color: $white;
            color: #00BA6E;
          }
        }
      }
    }
    .swiper {
      .swiper-pagination {
        bottom: -50px;
        @include tablets {
          bottom: -40px;
        }
      }
      .swiper_button_next {
        right: -15px;
        @include tablets {
          right: -30px;
        }
        @include desktops {
          right: -45px
        }
      }
      .swiper_button_prev {
        left: -15px;
        @include tablets {
          left: -30px;
        }
        @include desktops {
          left: -45px;
        }
      }
    }
  }
  .tech_section,
  .service_section {
    .section_title {
      padding-bottom: 8px;
      border-bottom: 2px solid #00BA6E;
      // font-family: DFMingStd;
      font-size: 24px;
    }
    .banner {
      margin: 30px 0 40px 0;
      height: 230px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      @include tablets {
        margin-top: 32px;
        height: 320px;
      }
      @include desktops {
        margin: 47px 0 43px 0;
      }
    }
  }
  .tech_section {
    margin-bottom: 50px;
    .banner {
      background-image: url('/images/yonglin/banner/banner_003_s.jpg');
      @include tablets {
        background-image: url('/images/yonglin/banner/banner_003_m.jpg');
      }
      @include desktops {
        background-image: url('/images/yonglin/banner/banner_003_l.jpg');
      }
    }
    .swiper-container {
      margin-bottom: 60px;
      @include desktops {
        margin-bottom: 40px;
      }
    }
    p {
      font-size: 18px;
      font-weight: 700;
    }
    .btn {
      margin-top: 30px;
      padding: 0;
      width: 195px;
      height: 47px;
      line-height: 47px;
      background-color: #00BA6E;
      border-radius: 24px;
      font-size: 18px;
      color: $white;
    }
  }
  .service_section {
    margin: 0 0 50px 0;
    @include tablets {
      margin: 0 0 74px 0;
    }
    @include desktops {
      margin: 0 0 80px 0;
    }
    .banner {
      background-image: url('/images/yonglin/banner/banner_002_s.jpg');
      @include tablets {
        background-image: url('/images/yonglin/banner/banner_002_l.jpg');
      }
      @include desktops {
        padding: 34px 0 0 60px;
      }
      p {
        // font-family: 'CSong3HK';
        font-size: 24px;
        color: $white;
        line-height: 49px;
      }
    }
    .service_item {
      padding: 32px 28px 32px 40px;
      border-top: 2px solid #00BA6E;
      @include tablets {
        padding: 38px 40px;
        border-width: 3px;
      }
      @include desktops {
        border-width: 2px;
      }
      &:last-child {
        border-bottom: 2px solid #00BA6E;
        @include tablets {
          border-width: 3px;
        }
        @include desktops {
          border-width: 2px;
        }
      }
      h4 {
        margin: 0;
        // font-family: DFMingStd;
        font-size: 18px;
        @include tablets {
          font-size: 24px;
        }
      }
      i {
        color: #00BA6E;
        font-size: 16px;
        transform: rotate(180deg);
      }
    }
    .detail_list {
      padding-top: 30px;
    }
    .detail_item {
      margin: 0 0 14px 0;
      .title {
        display: inline-block;
        // font-family: DFHeiSt;
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 4px;
        @include tablets {
          font-size: 20px;
        }
        &[aria-expanded="true"] {
          color: #00BB6B;
          border-bottom: 1px solid #00BB6B;
        }
        &[aria-expanded="false"] {
          border-bottom: 1px solid $black;
        }
      }
      ul {
        list-style: decimal;
        padding-top: 20px;
        padding-left: 15px;
        &.list-unstyled {
          list-style: none;
          padding-left: 0;
        }
      }
      li {
        // font-family: DFHeiSt;
        font-weight: 300;
      }
    }
  }
  .farmer_section {
    flex: 1 1 auto;
    .farmer_image {
      flex: 0 0 120px;
      width: 120px;
      height: 120px;
      margin: 0 0 14px 0;
      border: 4px solid #00BB6B;
      border-radius: 50%;
      overflow: hidden;
      @include tablets {
        margin: 0 85px 0 0;
        flex: 0 0 180px;
        width: 180px;
        height: 180px;
      }
      @include desktops {
        margin: 0 56px 0 74px;
        flex: 0 0 240px;
        width: 240px;
        height: 240px;
      }
    }
    .farmer_info {
      // font-family: DFHeiSt;
      .name {
        margin: 0 0 20px 0;
        font-size: 20px;
        font-weight: 700;
      }
      span {
        display: flex;
        align-items: center;
        margin: 0 0 10px 0;
        font-size: 15px;
        font-weight: 500;
      }
      p {
        margin: 14px 0 0 0;
        font-size: 14px;
        font-weight: 300;
      }
      i {
        margin: 0 9px 0 0;
      }
    }
    .farm_info_switch {
      padding: 0 42px;
      margin: 33px 0 20px 0;
      border-top: 1px solid #D8D8D8;
      border-bottom: 1px solid #D8D8D8;
      @include tablets {
        margin: 47px 0 40px 0 ;
        padding: 0;
      }
      @include desktops {
        margin: 34px 0 30px 0;
      }
      .switch_btn {
        position: relative;
        // font-family: DFHeiSt;
        font-size: 15px;
        font-weight: 500;
        color: $black;
        line-height: 37px;
        @include tablets {
          margin: 0 40px 0 0;
          font-size: 16px;
        }
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 100%;
          z-index: 1;
          width: 100%;
          height: 1px;
          background-color: transparent;
        }
        &:hover {
          color: #00BB6B;
        }
        &.switch_btn_active {
          color: #00BB6B;
          &:after {
            background-color: #00BB6B;
          }
        }
      }
    }
    .farm_info_images {}
    .farm_image {
      margin: 0 0 10px 0;
    }
  }
  .program_section {
    // padding: 0 0 50px 0;
    // background-image: url('/images/yonglin/tech/bg.jpg');
    // background-repeat: no-repeat;
    // background-size: 956px;
    // background-position: 65% 100%;
    // @include tablets {
    //   background-size: 1232px;
    //   background-position: 50% 137%;
    // }
    // @include desktops {
    //   background-position: 195px 370px;
    // }
    .swiper-container {
      padding: 0 0 50px 0;
      background-image: url('/images/yonglin/tech/bg.jpg');
      background-repeat: no-repeat;
      background-size: 956px;
      background-position: 65% 100%;
      @include tablets {
        background-size: 1232px;
        background-position: 50% 137%;
      }
      @include desktops {
        background-position: -73% 115%;
      }
    }
    .program {
      .program_header {
        min-height: 151px;
        margin: 0 0 20px 0;
        .label {
          width: 88px;
          height: 40px;
          margin: 0 auto 17px auto;
          background-color: #00BB6B;
          border-radius: 20px;
          line-height: 40px;
          font-size: 24px;
          font-weight: 700;
          color: $white;
        }
        h3 {
          margin-bottom: 10px;
          font-size: 30px;
        }
        p {
          margin: 0;
        }
      }
      .program_content {}
      .program_cost,
      .program_service {
        li {
          margin: 0 0 4px 0;
          padding: 8px 62px 8px 62px;
          background-color: #EBEBE3;
          span {
            flex: 1 1 50%;
            font-weight: 700;
            color: #4A4A4A;
          }
          &:first-child {
            border-radius: 15px 15px 0 0;
            span {
              color: $white;
            }
          }
          &:last-child {
            border-radius: 0 0 15px 15px;
          }
        }
      }
      .program_cost {
        position: relative;
        margin-bottom: 30px;
        .hint {
          position: absolute;
          top: -30px;
          left: 0;
          z-index: 1;
          width: 62px;
          height: 62px;
          border-radius: 50%;
          background-color: #BAD013;
          color: $white;
          font-size: 24px;
          font-weight: 700;
        }
        li {
          &:first-child {
            background-color: #4A4A4A;
          }
        }
      }
      .program_service {
        li {
          padding: 8px 45px 8px 45px;
          &:first-child {
            background-color: #00BA6E;
          }
          span {
            text-align: center;
            font-size: 14px;
          }
        }
      }
    }
  }
  .yonglin_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 50px 0 0 0;
    height: 20px;
    background-color: #00BA6E;
    color: $white;
    font-size: 12px;
    @include tablets {
      // margin: 74px 0 0 0;
      height: 40px;
      font-size: 15px;
    }
    @include desktops {
      // margin: 80px 0 0 0;
    }
  }
  .swiper {
    position: relative;
    .swiper-pagination {
      left: 50%;
      bottom: -35px;
      transform: translate3d(-50%, 0, 0);
    }
    .swiper-pagination-bullet {
      margin: 0 5px;
      width: 10px;
      height: 10px;
    }
    .swiper-pagination-bullet-active {
      background-color: #00BB6B;
    }
    .swiper_button_next,
    .swiper_button_prev {
      position: absolute;
      top: 50%;
      margin-top: 0;
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #00BA6E;
      &:focus {
        outline: none;
      }
    }
    .swiper_button_next {
      right: -15px;
      @include tablets {
        right: -20px
      }
    }
    .swiper_button_prev {
      left: -15px;
      @include tablets {
        left: -20px;
      }
    }
  }
}