@keyframes rollingScale {
  0% {
    transform: rotate(0deg) scale(0);
  }
  25% {
    transform: rotate(90deg) scale(0.25);
  }
  50% {
    transform: rotate(180deg)  scale(0.5);
  }
  75% {
    transform: rotate(270deg)  scale(0.75);
  }
  100% {
    transform: rotate(360deg)  scale(1.0);
  }
}
@keyframes scroll {
  0% {
    transform: translate(0%, 0%);
  }
  100%{
    transform: translate(0%, 27%);
  }
}
@keyframes scroll_color {
  0% {
    color: $white;
  }
  100%{
    color: $black;
  }
}
@keyframes slideDown {
  0% {
    top: -80px;
  }
  100% {
    top: 0;
  }
}
.animated_fadeIn {
  opacity: 0;
  transition: all .8s ease-in;
  transform: translate(0, 50px);
}
.fadeIn {
  opacity: 1;
  transform: translate(0, 0);
}