.section_en_title,
.section_title,
.verticle_line {
  text-align: center;
}
.verticle_line {
  width: 1px;
  height: 35px;
  margin: 0 auto 20px auto;
  background-color: #4a4a4a;
  @include tablets {
    margin-bottom: 15px;
  }
}
.section_en_title {
  // margin-bottom: 20px;
  margin-bottom: 10px;
  font-family: 'Sorts Mill Goudy';
  // font-size: 30px;
  font-size: 36px;
  // line-height: 43px;
  line-height: 52px;
  @include tablets {
    margin-bottom: 12px;
    font-size: 36px;
    line-height: 52px;
  }
}
.section_title {
  margin-bottom: 10px;
  font-family: 'FutoMinA101Pr5-Bold-AlphaNum';
  // font-size: 16px;
  font-size: 18px;
  line-height: 27px;
}  
.section_subtitle {
  margin-bottom: 20px;
  font-size: 14px;
  // font-family: 'Noto Serif', 'Noto Serif TC', 'Noto Serif SC', 'Noto Serif', 'Noto Serif JP', serif;
  font-family: 'FutoMinA101Pr5-Bold-AlphaNum';
  font-weight: 500; 
  line-height: 26px;
  @include tablets {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
  }
}
.section_content {
  margin-bottom: 50px;
  font-size: 14px;
  font-weight: 300;
  line-height: 26px;
  @include tablets {
    margin-bottom: 64px;
    text-align: center;
    line-height: 42px;
    font-size: 15px;
  }
}