.brand_section {
  position: relative;
  // @include tablets {
  //   height: calc(var(--vh, 1vh) * 100 - 50.8px);
  // }
  // @include desktops {
  //   height: auto;
  // }
  &.brand_toast {
    .brand_image {
      background-image: url('/images/brands_image/toast_lab.jpg');
      @include desktops_1920 {
        background-image: url('/images/brands_image/toast_lab.jpg');
        background-position: center 100%;
      }
    }
  }
  &.brand_flagship {
    .brand_image {
      background-image: url('/images/brands_image/flagship.jpg');
      @include desktops_1920 {
        background-image: url('/images/brands_image/flagship.jpg');
        background-position: center 100%;
      }
    }
  }
  &.brand_3393 {
    .brand_image {
      background-image: url('/images/brands_image/3393.jpg');
    }
  }
  &.brand_club {
    .brand_image {
      background-image: url('/images/brands_image/club_m.jpg');
      @include tablets{
        background-image: url('/images/brands_image/club.jpg');
        background-position: bottom;
      }
    }
    .brand_content {
      .desc {
        @include tablets {
          max-width: 100%;
        }
        @include desktops_1920 {
          max-width: 354px;
        }
      }
    }
  }
  &.brand_wudowuer {
    .brand_image {
      background-image: url('/images/brands_image/gping.jpg');
    }
  }
  .brand_image {
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @include tablets {
      height: 503px;
    }
    @include desktops {
      height: calc(var(--vh, 1vh) * 100 - 90px);
    }
    @include desktops_1920 {
      height: calc(var(--vh, 1vh) * 100 - 90px);
    }
  }
  &.brand_health {
    .brand_image {
      position: relative;
      margin: 0 0 30px 0;
      height: calc(var(--vh, 1vh) * 100 - 48px);
      min-height: calc(var(--vh, 1vh) * 100 - 48px);
      background-image: url('/images/brands_image/health/banner_bg_s.jpg');
      @include tablets {
        margin: 0 0 58px 0;
        height: calc(428px - 48px);
        min-height: calc(428px - 48px);
        background-image: url('/images/brands_image/health/banner_bg_l.jpg');
        background-position: center 100%;
      }
      @include laptop {
        margin: 0 0 60px 0;
        height: calc(var(--vh, 1vh) * 100 - 90px);
        min-height: calc(var(--vh, 1vh) * 100 - 90px);
        background-image: url('/images/brands_image/health/banner_bg_xl.jpg');
      }
      @include desktops {
        background-image: url('/images/brands_image/health/banner_bg_2xl.jpg');
      }
      @include desktops_1920 {
        margin: 0 0 80px 0;
        height: 807px;
        min-height: 807px;
      }
    }
  }
  .container {
    @include tablets {
      max-width: 690px;
    }
    @include desktops {
      padding: 0 50px 0 37px;
    }
    @include desktops_1920 {
      padding: 0 67px 0 140px;
    }
  }
  .brand_content {
    margin: 40px 0 80px 0;
    color: #4A4A4A;
    font-size: 14px;
    scrollbar-width: 5px;
    &::-webkit-scrollbar {
      width: 3px;
      background-color: #D8D8D8;
    }
    // &::-webkit-scrollbar-button {}
    &::-webkit-scrollbar-thumb {
      background-color: #9B9B9B;
      // -webkit-border-radius: 4px;
      // border-radius: 4px;
    }
    // &::-webkit-scrollbar-track {
    //   -webkit-border-radius: 10px;
    //   border-radius: 10px;
    // }
    // &::-webkit-scrollbar-track-piece {}
    // &::-webkit-scrollbar-corner {}
    // &::-webkit-resizer {}
    @include tablets {
      margin: 40px 0 68px 0;
    }
    @include desktops {
      padding: 0 75px 0 0;
      margin: 48px 0 0 0;
      display: flex;
      flex-direction: column;
      min-height: 450px;
      max-height: 450px;
      overflow-y: auto;
    }
    @include desktops_1920 {
      margin: 175px 0 0 0;
      padding: 0 75px 0 0;
      min-height: 560px;
      max-height: 560px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
    }
    .name {
      margin-bottom: 10px;
      font-family: 'Noto Sans', 'Noto Sans TC', 'Noto Sans SC', 'Noto Sans JP', sans-serif;
      font-size: 16px;
      color: #C08933;
      @include tablets {
        margin-bottom: 17px;
      }
    }
    .desc {
      margin-bottom: 32px;
      font-weight: 300;
      line-height: 22px;
      text-align: justify;
      word-break: break-all;
      @include tablets {
        // max-width: 506px;
        // max-width: unset;
      }
      @include desktops {
        // max-width: 354px;
        // max-width: unset;
        margin-bottom: 30px;
        flex: 1 1 auto;
      }
      @include desktops_1920 {
        // max-width: 354px;
        // max-width: unset;
        margin-bottom: 48px;
        flex: 1 1 auto;
      }
    }
    ul {
      margin-bottom: 32px;
      font-weight: 300;
      li {
        // margin-bottom: 9px;
        margin: 0 0 14px 0;
        text-align: justify;
        word-break: break-all;
      }
      &.contact {
        margin-bottom: 0;
        li {
          margin-bottom: 2px;
        }
      }
      i:before {
        margin-left: 0;
      }
    }
    .social_media {
      display: flex;
      align-items: center;
      margin-top: 5px;
      font-weight: 300;
      span {
        margin-right: 10px;
        font-family: 'Noto Sans', 'Noto Sans TC', 'Noto Sans SC', 'Noto Sans JP', sans-serif;
      }
      a {
        font-size: 16px;
        vertical-align: middle;
        color: #4A4A4A;
        &:nth-child(1) {
          color: #3b5998;
        }
        &:nth-child(2) {
          color: #00c300;
        }
      }
    }
  }
  &.brand_health {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #F5F6F8;
    background-image: url('/images/brands_image/health/bg_mask.png');
    .container {
      padding: 0 15px;
    }
    .brand_about,
    .brand_product {
      .section {
        max-width: 315px;
        margin: 0 auto 20px auto;
        @include tablets {
          max-width: 510px;
        }
        @include desktops {
          margin: 0 auto 40px auto;
          max-width: 594px;
        }
        &:last-child {
          margin: 0 auto 0 auto;
        }
      }
      .section_main {
        margin: 0 auto 20px auto;
        @include tablets {
          // margin: 0 auto 60px auto;
        }
      }
    }
    .brand_about {
      position: relative;
      margin-bottom: 40px; 
      @include tablets {
        margin-bottom: 60px;
      }
      .bg_image {
        position: absolute;
        &.bg_image_left {
          top: 40px;
          left: -33%;
          max-width: 370px;
          @include desktops {
            top: 8px;
            left: -77%;
            max-width: 620px;
          }
          @include desktops_1920 {
            left: -80%;
          }
        }
        &.bg_image_right {
          top: 380px;
          right: 0;
          max-width: 274px; 
          right: -14%;
          @include desktops {
            top: 238px;
            max-width: 456px;
            right: -58%;
          }
          @include desktops_1920 {
            top: 241px;
            right: -68%;
          }
        }
        &.bg_image_right[data-aos='fade-left'] {
          transform: translate3d(100px, 0, 0) rotate(346deg);
        }
        &.bg_image_right[data-aos^='fade-left'][data-aos^='fade-left'] {
          &.aos-animate  {
            transform: translate3d(0, 0, 0) rotate(346deg);
          }
        }
      }
    }
    .brand_product {
      position: relative;
      z-index: 1;
      padding: 0 0 133px 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @include tablets {
        padding: 0 0 326px 0;
      }
      @include desktops {
        padding: 0 0 445px 0;
      }
      @include desktops_1920 {
        padding: 0 0 781px 0;
      }
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        height: 172px;
        background-image: url('/images/brands_image/health/btm_bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        @include tablets {
          height: 354px;
        }
        @include desktops {
          height: 553px;
        }
        @include desktops_1920 {
          height: 885px;
        }
      }
      .container {
        @include tablets {
          max-width: 570px;
        }
        @include desktops {
          max-width: 960px;
        }
      }
      .section {
        @include tablets {
          max-width: unset;
        }
      }
      .card {
        border: none;
        border-radius: 0;
        margin: 0 0 15px 0;
        background-color: transparent;
        @include tablets {
          margin: 0 0 20px 0;
        }
        @include desktops {
          margin: 0 0 50px 0;
        }
      }
      .card_header {
        margin: 0 0 15px 0;
        @include tablets {
          flex: 0 0 220px;
          margin: 0 30px 0 0;
        }
        @include desktops {
          margin: 0 0 30px 0;
          flex: unset;
        }
      }
      .card_body {
        text-align: center;
        @include tablets {
          flex: 1 1 auto;
          text-align: left;
          padding: 0;
        }
        @include desktops {
          padding: 0;
          text-align: center;
        }
        h3 {
          height: 35px;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow:hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 15px;
          margin: 0 0 2px 0;
          font-weight: 400;
          @include tablets {
            height: unset;
            font-size: 18px;
          }
          @include desktops {
            margin: 0 0 10px 0;
            font-size: 24px;
          }
        }
        span {
          font-size: 13px;
          font-weight: 300;
          height: 35px;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow:hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          @include tablets {
            font-size: 14px;
          }
          @include desktops {
            font-size: 18px;
          }
        }
        ul {
          display: none;
          @include tablets {
            display: block;
            font-size: 14px;
            font-weight: 300;
          }
          @include desktops {
            display: none;
          }
        }
      }
      .card_btn {
        margin: 15px 0 0 0;
        @include tablets {
          margin: 20px 0 0 0;
        }
        a {
          min-width: 100%;
          padding: 4px 0 ;
          background-color: #197B70;
          color: $white;
          font-size: 14px;
          font-weight: 400;
          transition: all .3s ease-in-out;
          text-align: center;
          &:hover {
            background-color: #38BCAD;
          }
          @include tablets {
            min-width: 152px;
          }
        }
      }
    }
    .section_main {
      .section_body {
        p {
          margin: 0;
          font-size: 13px;
          font-weight: 300;
          line-height: 19px;
          @include tablets {
            font-size: 14px;
            font-weight: 300;
            line-height: 30px;
          }
          @include desktops {
            font-size: 18px;
          }
        }
      }
    }
    .section_header {
      color: #1E7C71;
      font-weight: 400;
      text-align: center;
      .icon {
        max-width: 10px;
        margin: 0 auto 6px auto;
        @include tablets {
          max-width: 15px;
          margin: 0 auto 15px auto;
        }
      }
      .title {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 20px 0;
        font-size: 18px;
        font-weight: 400;
        white-space: nowrap;
        @include tablets {
          margin: 0 0 20px 0;
          font-size: 22px;
        }
        @include desktops {
          font-size: 28px;
        }
        &::before,
        &:after {
          content: '';
          flex: 0 0 50px;
          border-top: 1px solid #9B9B9B;
        }
        &:before {
          margin: 0 20px 0 0;
        }
        &:after {
          margin: 0 0 0 20px;
        }
      }
      .sub_title {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 400;
        @include tablets {
          font-size: 21px;
        }
        @include desktops {
          margin-bottom: 20px;
          font-size: 26px; 
        }
        @include desktops_1440 {
          margin-bottom: 30px;
        }
      }
    }
    .section_body {
      p {
        margin: 0;
        font-size: 13px;
        font-weight: 300;
        line-height: 19px;
        @include tablets {
          // font-size: 14px;
          // font-weight: 300;
          line-height: 30px;
        }
        @include desktops {
          font-size: 16px;
        }
      }
    }
  }
}