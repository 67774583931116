@mixin tablets {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin laptop {
  @media (min-width: 992px) {
    @content;
  }
}
@mixin desktops {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin desktops_1440 {
  @media (min-width: 1440px) {
    @content;
  }
}
@mixin desktops_1920 {
  @media (min-width: 1920px) {
    @content;
  }
}