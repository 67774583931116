@import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,400,500,700&display=swap&subset=chinese-traditional');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+SC:300,400,500,700&display=swap&subset=chinese-simplified');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400,500,700&display=swap&subset=japanese');
@import url('https://fonts.googleapis.com/css?family=Noto+Serif:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Noto+Serif+TC:400,500,700&display=swap&subset=chinese-traditional');
@import url('https://fonts.googleapis.com/css?family=Noto+Serif+SC:400,500,700&display=swap&subset=chinese-simplified');
@import url('https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,500,700&display=swap&subset=japanese');
@font-face {
  font-family: 'Sorts Mill Goudy';
  src: url('/font/SortsMillGoudy-Italic.eot?#iefix') format('embedded-opentype');
  src: url('/font/SortsMillGoudy-Italic.woff2') format('woff2'),
      url('/font/SortsMillGoudy-Italic.woff') format('woff'),
      url('/font/SortsMillGoudy-Italic.ttf') format('truetype'),
      url('/font/SortsMillGoudy-Italic.svg#SortsMillGoudy-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Garamond MT Std';
  src: url('/font/Garamond MT Std.woff2') format('woff2'),
      url('/font/Garamond MT Std.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'FutoMinA101Pr5-Bold-AlphaNum';
  src: url('/font/FutoMinA101Pr5-Bold-AlphaNum.otf') format('opentype'),
      url('/font/FutoMinA101Pr5-Bold-AlphaNum.woff2') format('woff2'),
      url('/font/FutoMinA101Pr5-Bold-AlphaNum.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
	font-family: 'TrajanPro3';  
	src:  url('/font/TrajanPro3-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
  font-family: 'CSong3HK';
	font-style: normal;
	font-display: swap;
  src: url('/font/CSong3HK-Medium.woff2') format('woff2'), /* Super Modern Browsers */
       url('/font/CSong3HK-Medium.woff') format('woff'); /* Modern Browsers */
}
@font-face{
	font-family: DFMingStd;
	src: url('/font/DFMingStd-W7.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face{
	font-family: DFHeiSt;
	src: url('/font/DFHeiSt0.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}