/* 
  1.為防止使用 vw 造成滾動條產生，必須將 body 加上 overflow-x: hidden
*/
html {
  position: relative;
  width: 100%; // 選加
  height : 100%; // 選加
  // overflow-x: hidden;
}
body {
  position: relative;
  width: 100%;  // 選加
  height: 100%; // 選加
  color: $black;
  overflow-x: hidden; // 必加
}
/*  */
a {
  &:hover {
    text-decoration: none;
  }
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.container {
  padding: 0 43px;
  @include tablets {
    padding: 0 15px;
  }
}
.link {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  background-color: #C08933;
  color: $white;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  transition: all .3s ease-out;
  &:hover {
    color: $white;
    background-color: #00354E;
  }
}

.font-weight_m {
  font-weight: 500 !important;
}

[v-cloak] {
  display: none;
}